.slideInfoComponent {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    color: #ffffff;
    text-align: center;
    margin: 0 0 30px;
}

.slideInfoComponent.header {
    font-size: 36px;
    line-height: 1.4;
}

.slideInfoComponent.subHeader {
    font-size: 22px;
    line-height: 1.4;
}

.slideInfoContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: left;
    background: rgba(0, 0, 0, 0.6);
    padding: 50px;
    margin: 20px;
}