.NursingPlacementItem {
  display: flex;
}

.container {
  display: flex;
  flex: 1;
}

.textContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 15px;
}

.imageContainer {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0; /* Prevent image from shrinking */
}

.img {
  margin: 0 10px 10px 50px;
  width: 200px;
  height: 142px;
}

.info {
  max-width: 450px; /* Adjust to match width of original CPNW site */
}

.info a {
  display: unset !important;
  margin: unset !important;
  padding: unset !important;
  color: #007e8d !important;
  text-decoration: none !important;
  outline: none !important;
  border: none !important;
}

.info a:hover {
  color: #aa9b73 !important;
}

.info p {
  color: #3d3d3d;
  letter-spacing: 1px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  /* Add bottom margin for paragraphs */
  margin: 0 0 30px 0;
}
