.ButtonRow{
    text-align: center;
    margin: 20px;
    
}

.downloadPStyles{
    margin: 0 0 30px;
    font-family: inherit;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 1px;
    text-rendering: optimizeLegibility;
    line-height: 1.6;
    color: #3d3d3d;
}

/* .downloadDiv{
    margin-top: 140px;
} */