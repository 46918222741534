.placementListComponent {
  line-height: 1.7;
  font-size: 14px;
  width: 100%;
  font-weight: 300;
  letter-spacing: 1px;
  color: #3d3d3d;
  margin: 15px;
}

.placementListComponent h1 {
  margin: 0px;
  font-size: 20px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}

.placementListComponent ul {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  padding-left: 0;
}

.placementListComponent li {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}

.placementListComponent a {
  color: #007e8d;
  text-decoration: none;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.placementListComponent a:hover {
  color: #aa9b73;
}
