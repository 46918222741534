.archive_banner{
    width: 100%;
    height: 30dvh;
    background-color: darkred;
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    font-family: "Roboto", sans-serif;
}

.archive_banner>h4,
.archive_banner>p{
    font-size: 20px;
    margin-bottom: 0;
}