.partners_list_wrapper{
    padding: 0px 15px;
}

/* This fixes the gap between first and second column in partners list*/
.partners_list_wrapper>div>div:nth-child(2)>div:nth-child(2){
    padding-left: 0px;
}

/* Partners List text from anchor tags were not wrapping, and were clipping outside the available space and into the next column causing an text meshing */
.partners_list_wrapper>div>div:nth-child(2)>div>ul>li>a{
    text-wrap: wrap;
}

/* Partners List breakpoint for swapping from row/col to just col and adjusting spacing/margin/padding */
@media screen and (max-width: 680px){
    .partners_list_wrapper>div>div:nth-child(2){
        display: flex;
        flex-direction: column;
    }
    .partners_list_wrapper>div>div:nth-child(2)>div{
        width: 100%;
    }
    .partners_list_wrapper>div>div:nth-child(2)>div>ul{
        margin-bottom: 0;
    }
    .partners_list_wrapper>div>div:nth-child(2)>div>ul>li{
        margin-bottom: 5px 
        /* This makes the seperation from each item more apparent and easier to read */
    }
    .partners_list_wrapper>div>div:nth-child(2)>div:nth-child(2){
        padding-left: 0px;
    }
}