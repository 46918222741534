@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.galleryContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .25);
}

.buttonPrev,
.buttonNext {
  position: absolute;
  font-family: "Material Icons";
  font-size: 4rem;
  cursor: pointer;
  user-select: none;
  z-index: 9999;
}

.buttonPrev {
  left: 5%;
}

.buttonNext {
  right: 5%;
}

.buttonPrev:hover,
.buttonNext:hover {
  color: white;
}

.galleryTrack {
  aspect-ratio: 168/100;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slideImage {
  aspect-ratio: 168/100;
  position: absolute;
  background-size: cover;
  width: 100%;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slideContent {
  position: relative;
  text-align: center;
}

.slideContent {
  top: 40% !important;
}

.slideContent p {
  margin: 0 0 30px;
}

.slideImage::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}

.galleryFooter {
  position: absolute;
  bottom: 4%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.bubbleOuter {
  cursor: pointer;
  border: 3px solid gray;
  background-color: gray;
  border-radius: 8px;
}

.bubbleInner {
  border: 3px solid transparent;
  border-radius: 4px;
}

@media screen and (max-width: 800px) {
  .slideContent {
    top: 52% !important;
  }

  .slideContent h2 {
    font-size: 28px !important;
  }

  .slideContent p {
    font-size: 16px !important;
  }

  .slideContent button {
    font-size: 15px !important;
  }
}

@media only screen and (max-height: 900px) {
  .slideContent {
    top: 52% !important;
  }
}

@keyframes slideRightIn {
  from {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  to {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slideLeftIn {
  from {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slideRightOut {
  from {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }

  to {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes slideLeftOut {
  from {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }

  to {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.rightIn {
  -webkit-animation: slideRightIn 1s 1 forwards;
  -moz-animation: slideRightIn 1s 1 forwards;
  -ms-animation: slideRightIn 1s 1 forwards;
  -o-animation: slideRightIn 1s 1 forwards;
  animation: slideRightIn 1s 1 forwards;
}

.leftIn {
  -webkit-animation: slideLeftIn 1s 1 forwards;
  -moz-animation: slideLeftIn 1s 1 forwards;
  -ms-animation: slideLeftIn 1s 1 forwards;
  -o-animation: slideLeftIn 1s 1 forwards;
  animation: slideLeftIn 1s 1 forwards;
}

.rightOut {
  -webkit-animation: slideRightOut 1s 1 forwards;
  -moz-animation: slideRightOut 1s 1 forwards;
  -ms-animation: slideRightOut 1s 1 forwards;
  -o-animation: slideRightOut 1s 1 forwards;
  animation: slideRightOut 1s 1 forwards;
}

.leftOut {
  -webkit-animation: slideLeftOut 1s 1 forwards;
  -moz-animation: slideLeftOut 1s 1 forwards;
  -ms-animation: slideLeftOut 1s 1 forwards;
  -o-animation: slideLeftOut 1s 1 forwards;
  animation: slideLeftOut 1s 1 forwards;
}