.aboutUsPage {
  display: flex;
  flex-direction: column;
}

.aboutUsPage a{
  white-space: nowrap;
}

.contentContainer {
  display: flex;
  flex-direction: row;
}

.nav {
  min-width: 220px;
}

.content {
  margin-top: 75px;
  padding: 0 15px;
}

.content h1 {
  margin: unset !important;
  font-size: 35px;
  color: #007e8d;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.content h1::after {
  content: '';
  display: block;
  margin: 15px 0 40px;
  height: 1px;
  width: 150px;
  background: #007e8d;
}

.item:not(:last-child) {
  margin-bottom: 31px;
}

@media screen and (max-width: 680px) {
  .contentContainer {
      flex-direction: column;
  }
  .content {
    margin-top: unset;
  }
}