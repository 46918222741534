.aboutUs .itemCenter {
  text-align: center;
  margin-bottom: 1em;
}

.aboutUs a,
.aboutUs a:visited {
  color: #007e8d;
  text-decoration: none;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.aboutUs a:hover {
  color: #aa9b73;
}

.aboutUs p {
  margin: 0 0 30px;
}


.aboutUs .row {
  display: flex;
  flex-wrap: wrap;
}

.aboutUs .column {
  flex: 1;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.aboutUs ul{
  margin: 0px;
}

.aboutUs li svg{
  left: -30px;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
  padding-top: 5px;
}

@media (max-width: 767px) {
  .aboutUs .column {
    flex-basis: 100%;
  }
}