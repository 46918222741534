.listItemStyle {
    display: list-item;
    margin-left: 1.25rem;
    margin-bottom: 0;
    list-style-type: circle;
    padding-left: 0px;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.6;
    list-style-position: outside;
}

strong, b {
    color:#007e8d;
    font-weight: bold;
    line-height: inherit;
}