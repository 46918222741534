.registerComponent {
    margin: 0;
    padding: 0;

    h3 {
        margin: 0 0 30px;
    }

    .formCaption {
        font-size: 11px;
        letter-spacing: 1px;
        color: #3d3d3d;
    }

    .formCaption>p {
        font-size: 11px;
        padding: 10px 0;
        margin: 0;
        text-align: left;
        color: #3d3d3d;
    }

    a,
    a:visited {
        color: #007e8d;
        text-decoration: none;
        outline: none;
        border: none;
    }

    a:hover {
        color: #aa9b73;
    }

    select,
    input {
        margin: 0px !important;
    }

    select:hover,
    option {
        color: #007e8d;
    }

    button.disabled,
    button[disabled] {
        cursor: default !important;
        background-color: #008CBA;
        border-color: #007095;
        color: #FFFFFF;
        cursor: default;
        opacity: 0.7;
        box-shadow: none;
    }

    button.disabled:hover,
    button[disabled]:hover {
        background-color: #008CBA;
    }

    .button {
        cursor: pointer !important;
        background: #007e8d;
        color: #ffffff;
        font-weight: 300;
        text-transform: uppercase;
        padding: 0px 20px;
        margin: 0 0 1.25rem;
        letter-spacing: 1px;
        height: 37px;
    }

    .button:hover {
        background: #aa9b73;
    }

    .jsonError {
        color: #3d3d3d;
    }

    span.error,
    small.error {
        display: block;
        margin-top: -1px;
        margin-bottom: 1rem;
        font-size: 0.75rem;
        font-weight: normal;
        font-style: normal;
        background: none;
        color: #e00000;
        padding: 3px 0 2px 0;
    }

    .alertBox {
        border-style: solid;
        border-width: 1px;
        display: block;
        font-weight: normal;
        margin-bottom: 1.25rem;
        position: relative;
        padding: 0.875rem 1.5rem 0.875rem 0.875rem;
        font-size: 0.8125rem;
        transition: opacity 300ms ease-out;
        background-color: #008CBA;
        border-color: #0078a0;
        color: #FFFFFF;
    }

    .alertBox.radius {
        border-radius: 3px;
    }

    .alertBox.alert {
        background-color: #f04124;
        border-color: #de2d0f;
        color: #FFFFFF;
    }

    .medium5 {
        width: 60%;
    }
}