.reqExh2{
    font-size: 30px;
    font-family:"Roboto", sans-serif;
    font-weight: 300;
    margin: 0 0 30;
    font-style: normal;
    color:#222222;
    text-rendering: optimizeLegibility;
    line-height: 1.4;

}
.smallTextColor {
    color: #007e8d !important;
}
.fieldsetLegend{
    font-weight: bold;
    background: #FFFFFF;
    padding: 0 0.1875rem;
    margin: 0;
    margin-left: -0.1875rem;
}
.text{
    line-height: 1.7;
    font-size: 14px;
    letter-spacing: 1px;
    color: #3d3d3d;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    -webkit-text-size-adjust: 100%;

}
.fieldsetSection {
    border-left: none;
    border-right: none;
    border-top: none;
    margin: 0 0 30px;
    padding: 0 0 20px;    

}
.hrStyle{
margin: 30px 0 0;
padding: 20px 0 0;
}