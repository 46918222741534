.resetPasswordForm {
    max-width: 296px;
    display: flex;
    flex-direction: column;
}

.resetPasswordForm > span { 
    color: #222222;
    font-size: 20px;
    line-height: 1.4;
    font-style: normal;
    text-rendering: optimizeLegibility;
    font-weight: 300;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    margin-bottom: 35px;
}

/* .submitBtn:hover {
    background-color: #aa9b73;
} */

.hover:hover{
    background-color: #aa9b73;
}