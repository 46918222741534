.loginForm {
  display: flex;
  flex-direction: column;
}

.loginForm .header {
  font-size: 20px !important;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #222222;
  text-rendering: optimizeLegibility;
  margin-bottom: 30px;
}

.loginForm ul {
  margin: unset unset 10px unset !important;
}

.loginForm li {
  list-style-type: disc;
}

.form label {
  margin: unset !important;
}
.form input {
  margin-bottom: 0 !important;
}

.form span {
  margin-top: 5px;
  margin-bottom: 10px;
  line-height: 1.7;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  color: #3d3d3d;
  font-family: "Roboto", sans-serif;
}

.formFields {
  display: flex;
  flex-direction: column;
}

.submitBtn:hover {
  background-color: #aa9b73;
}
