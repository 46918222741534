/* Copied from GetStarted.module.css */


.nursingPlacement {

}

.nursingPlacement h1 {
  margin: unset !important;
  font-size: 35px;
  color: #007e8d;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.nursingPlacement h1::after {
  content: "";
  display: block;
  margin: 15px 0 40px;
  height: 1px;
  width: 150px;
  background: #007e8d;
}

.item:not(:last-child) {
  margin-bottom: 31px;
}

/* Anchor tag in third paragraph (nursing placement item) */
.item>div>div>div:nth-child(1)>div>p>strong>a{
  font-weight: bold;
  font-size: 14px;
}