.layout {
  display: flex;
  flex-direction: column;
}

.nav {
  min-width: 220px;
}

.adSpaceButton.homePage {
  padding: 195px 36px 0 36px;
  position: absolute;
  z-index: 2;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.adSpaceButton.static {
  width: 80%;
  max-width: 80%;
}

.carousel.homePage {
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.contentContainer {
  max-width: 1125px;
  width: 100%;
  margin: 10px auto 20px auto;
  border-top: 1px solid #ededed;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.contentContainer.homePage {
  border-top: none !important;
  padding-top: 0 !important;
}

@media screen and (max-width: 1100px) {
  .contentContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 770px) {
  .contentContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}
