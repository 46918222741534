.resetPasswordForm {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.resetPasswordContainer form{
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.resetPasswordContainer form div{
    width: 80%;
}

.tokenError {
    width: 100%;
    list-style-type: disc;
}

.submitBtn:hover {
    background-color: #aa9b73;
}
