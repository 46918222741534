.faqPage p>a,
.faqPage p>a:visited {
  display: inline;
  background: none;
  color: #007e8d !important;
  padding: 5px 10px;
  cursor: pointer;
  max-width: max-content;
  margin: 0;
  padding: 0;
  word-break: break-word;
}

.faqPage h1 {
  margin: 0 0 30px;
  font-size: 35px;
  color: #007e8d;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.faqPage .row {
  display: flex;
  flex-wrap: wrap;
}

.faqPage .column {
  flex: 1;
}

.columns {
  padding-left: 0;
}

@media (max-width: 767px) {
  .faqPage .column {
    flex-basis: 100%;
  }
}

.faqPage hr {
  margin: 50px 0;
  border: solid #DDDDDD;
  border-width: 1px 0 0;
  clear: both;
  height: 1;
  width: 150px;
  display: inline-block;
}


