.pagination {
    font-size: 14px;
    font-weight: 300;
    display: block;
    min-height: 1.5rem;
    padding-left: 20px;
    line-height: 1.6;
    margin-bottom: 1.25rem;
    margin-top: 1rem;
    letter-spacing: 1px;
}

.pagination li {
    float: left;
    color: #999999;
    font-size: 0.875rem;
    margin-left: 0.3125rem;
    display: block;
    background: none;
    font-weight: normal;
    line-height: inherit;
    height: 1.5rem;
    transition: background-color 300ms ease-out;
    border-radius: 3px;
    width: auto;
}

.pagination li a {
    padding: 0.0625rem 0.625rem 0.0625rem;
}

.pagination li:hover {
    background-color: #e6e6e6;
    cursor: pointer;
}

.pagination li a:hover {
    text-decoration: none;
    color: black;
}

.pagination li.onPage {
    background-color: #e6e6e6;
}

.pagination a {
    font-size: 12px;
    color: #999999;
}

.pagination li a:hover {
    color: #999999;
}