.schools {
}

.schools h1 {
  margin: unset !important;
  font-size: 35px;
  color: #007e8d;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.schools h1::after {
  content: "";
  display: block;
  margin: 15px 0 40px;
  height: 1px;
  width: 150px;
  background: #007e8d;
}

.item {
  margin-bottom: 20px;
}

span.error {
  display: inline-block;
  margin: 0 0 16px 0;
  color: #f04124;
  font-size: 0.75rem;
  font-weight: normal;
}

.formFields:last-child .error {
  margin-bottom: 0 !important;
}

span.required {
  color: #007e8d;
  font-size: 11px;
}

.schools input {
  margin-bottom: 5px !important;
}
.schools textarea {
  margin-bottom: 0 !important;
}

.schools h2 {
  margin: 0;
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #222222;
  text-rendering: optimizeLegibility;
}

.schools legend {
  font-weight: bold;
  background: #ffffff;
  padding: 0 0.1875rem;
  margin: 0;
  margin-left: -0.1875rem;
}

.schools p {
  margin: 0;
}

.formSection {
  margin-top: 30px;
  border-bottom: 1px solid #ededed;
  padding-bottom: 30px;
}

.formFill {
  margin-top: 36px;
}
.formFields {
  display: flex;
  flex-direction: row;
}

.fieldset {
  flex: 1;
}

.fieldset:not(:first-child) {
  margin-left: 20px;
}

span.addProgramSpan {
  color: #007e8d;
}

.addProgramBtn {
  margin: 30px 0;
}

.footer {
  display: flex;
  justify-content: center;
}

.submitBtn {
  background-color: #007E8D;
}
.submitBtn:hover {
  background-color: #aa9b73;
}

@media screen and (max-width: 680px) {
  .formFields {
    flex-direction: column;
  }

  .fieldset {
    margin-left: 0 !important;
  }

  span.error {
    margin-bottom: 13px;
  }

  .formFields:last-child .error {
    margin-bottom: 13px !important;
  }
}
