.registerPage {

  .container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    height: 100vh;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  .column {
    flex: 1;
    padding-left: 0;
    padding-right: 0.9375rem;
  }

  .column.firstColumn {
    flex: 1.2 1;
  }

  h3 {
    margin: 0 0 30px;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    color: #222222;
  }

  h1 {
    margin: 0 0 30px;
  }

  h5 {
    margin: 0;
    color: #007e8d;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  }

  h5 a {
    font-size: 16px;
  }

  a {
    background-color: transparent;
    cursor: pointer;
    color: #007e8d;
    text-decoration: none;
    outline: none;
    border: none;
  }

  a:hover {
    color: #aa9b73;
  }
}