.content {
    padding: 10px 0;
}
.highlightText {
    color: #007e8d;
    font-weight: bold;
}
.groupServiceSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    
}