.participating_programs_wrapper{
    padding: 0px 15px;
}

.participating_programs_wrapper>div>div:nth-child(2){
    padding: 0;
}

.participating_programs_header>div>div:nth-child(1)>div>h1:after{
    display: none;
}

.participating_programs_header>div>div:nth-child(2){
    margin-top: 45px;
    padding: 0;
}

@media screen and (max-width: 1024px) {
    .participating_programs_list_wrapper>div>div:nth-child(1)>div>div:nth-child(1){
        flex-grow: 1;
    }
}
