.slideButtonComponent.button {
    background-color: transparent;
    color: #FFFFFF;
    border: 3px solid #FFFFFF;
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 20px;
    font-weight: 550;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.5s ease;
    text-wrap: nowrap;
}

.slideButtonComponent.button:hover {
    background: #FFFFFF;
    color: #727272;
}

.slideButtonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: left;
    background: rgba(0, 0, 0, 0.6);
    padding: 50px;
    width: 70%;
}

.slideButtonContainer .button {
    margin: 20px;
}