.program_list_wrapper {
  max-width: 750px;
}

/* Search bar grows to full width of parent on smaller screen sizes */
@media screen and (max-width: 40.063em){
  .program_search_wrapper>div>div:nth-child(1){
    flex-grow: 1;
  }
}

.list_container{
  padding-left: 40px;
}

.pagination_wrapper{
  padding-left: 35px;
}