html body {
    line-height: 1.7;
    font-size: 14px;
    width: 100%;
    font-weight: 300;
    letter-spacing: 1px;
    color: #3d3d3d;
}

li {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
}

a {
    color: #007e8d;
    text-decoration: none;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

a:hover {
    color: #aa9b73;
}