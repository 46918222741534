.welcomeInfoBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    margin-top: -60px;
    margin-bottom: 90px;
}

.welcomeInfoBox .row {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.welcomeInfoBox .column {
    flex: 1;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
}

@media (max-width: 767px) {
    .welcomeInfoBox .column {
        flex-basis: 100%;
    }

    .welcomeInfoBox .row{
        width: 100%;
    }
}

.welcomeInfoBox .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.welcomeInfoBox h1 {
    font-size: 35px;
    color: #007e8d;
    margin-bottom: 20px;
}

.welcomeInfoBox .lineAfter:after {
    content: '';
    display: block;
    margin: 10px auto !important;
    height: 1px;
    width: 150px;
    background: #007e8d;
}

/*override global style*/
.welcomeInfoBox p {
    margin: unset !important;
}