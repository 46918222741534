.healthCare {
}

.healthCare h1 {
  margin: unset !important;
  font-size: 35px;
  color: #007e8d;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.healthCare h1::after {
  content: "";
  display: block;
  margin: 15px 0 40px;
  height: 1px;
  width: 150px;
  background: #007e8d;
}

.description {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
}

.imgContainer {
  margin-left: 50px;
  margin-top: 30px;
}

@media screen and (max-width: 680px) {
  .description {
    display: flex;
    flex-direction: column-reverse;
  }

  .imgContainer {
    margin-left: unset;
    margin-top: unset;
    text-align: center;
  }
}
