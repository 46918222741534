.link {
    color: #007e8d;
}

.subParagraph {
    margin-top: 0;
    padding-top: 0;
    padding-left: 2rem;
    font-weight:300;
    font-size: 14px;
    margin: 0 0 30px;
    margin-left: 1.25rem;
    text-rendering: optimizeLegibility;
    letter-spacing: 1px;
    color: #3d3d3d;
    font-family: "Roboto", sans-serif;
}

.olSubParagraph {
    margin-top: 0;
    padding-top: 0;
    padding-left:20px;
    font-weight:300;
    font-size: 14px;
    margin: 0 0 30px;
    margin-left: 1.4rem;
    text-rendering: optimizeLegibility;
    letter-spacing: 1px;
    color: #3d3d3d;
    font-family: "Roboto", sans-serif;
}

.subParagraphAlign {
    margin-top: 0;
    padding-top: 0;
    padding-left: 20px;
}
