.stepHeadStyle{
    font-size: medium;
    text-decoration: underline;
    margin-bottom:0;
    margin-top:20px;
    font-weight:300;
    margin: 0 0 30;
    font-family: inherit;
    line-height: 1.6;
    text-rendering: optimizeLegibility;
}