.subHeadParagraph{
    margin-top: -20px;
    padding-left: 2rem;
}
.subSubParagraph{
    padding-left: 2rem;
}
.liAlignment{
    margin-top: -1.25rem;
}
.listAlign{
    margin-left: 2rem;
}