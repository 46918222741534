strong {
    color: #007e8d;
    font-weight: bold;
    line-height: inherit;
}

.headerStyle {
    text-align: center;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    text-rendering: optimizeLegibility;
    letter-spacing: 1px;
}