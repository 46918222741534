.container {
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 70%;
}
.ResetPasswordPage {
  width: 100%;
  display: flex;
  justify-content: center;
}

a {
  background-color: transparent;
  cursor: pointer;
  color: #007e8d;
  text-decoration: none;
  outline: none;
  border: none;
}

a:hover {
  color: #aa9b73;
}
