.accountLogin {
}

.accountLogin h1 {
  margin: unset !important;
  font-size: 35px;
  color: #007e8d;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.accountLogin h1::after {
  content: "";
  display: block;
  margin: 15px 0 40px;
  height: 1px;
  width: 150px;
  background: #007e8d;
}

.container {
  display: flex;
  flex-direction: row;
}

.loginForm {
  flex: 2;
  padding-right: 60px;
  padding-bottom: 20px;
  border-right: 1px solid #dddddd;
}

.resetForm {
  margin-left: 60px;
}

@media screen and (max-width: 680px) {
  .container {
    flex-direction: column;
  }

  .loginForm {
    flex: unset !important;
    padding-right: unset !important;
    border-right: unset !important;

    padding-bottom: 50px;
    border-bottom: 1px solid #dddddd;
  }

  .resetForm {
    margin-top: 31px;
    margin-left: unset !important;
  }
}
