.container {
  display: flex;
  flex-direction: column;
  min-width: 40.063em;
  max-width: 40.063em;
  width: 40.063em;
  padding: 90px 0;
}

.linkResendCode {
  cursor: pointer;
  color: #0078a0;
}
.linkResendCode:hover {
  color: #aa9b73;
}

.container h2 {
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  color: #222222;
  text-rendering: optimizeLegibility;
}

.container .error {
  margin: 15px 0 20px 0;
}

.container h3 {
  margin: 0 0 5px 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.container h5 {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.container button {
  margin-right: 5px;
}
