.listHeaderOl{
    width: 100%;
    letter-spacing: 1px;
    color: #3d3d3d;
    font-family: "Roboto", sans-serif;    
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1.25rem;
    list-style-position: outside;
    font-family: inherit;
}

.listHeaderOlLi{
    margin-bottom: 20px;
    position: relative;
    font-weight: 800;
    font-size: medium;
    list-style-type: decimal;
}
