.container {
  display: flex;
  flex-direction: column;
  min-width: 40.063em;
  max-width: 40.063em;
  width: 40.063em;
  margin: 0 auto;
  text-align: center;
  padding: 90px 0;
}

.container h1 {
  font-size: 35px;
  color: #007e8d;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: 0 0 30px 0;
}

.container h1::after {
  content: "";
  display: block;
  height: 1px;
  width: 150px;
  margin: 0 auto;
  background: #007e8d;
}

.container h5 {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.center{
  margin-top: 20px;
}