.center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50dvh;
}

.error{
    color: red;
    margin-bottom: 10px;
    font-weight: bold;
}

.loader{
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
}

.loader>div{
    margin: 0;
}

.pendingReset{
    max-width: 297px;
}

.pendingReset>span{
    color: #222222;
    font-size: 20px;
    line-height: 1.4;
    font-style: normal;
    text-rendering: optimizeLegibility;
    font-weight: 300;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    margin-bottom: 35px;
}

.pendingReset>p{
    margin-top: 35px;
}