.smallTextColor {
    color: #007e8d !important;
}
.fieldsetLegend {
    font-weight: bold;
    background: #FFFFFF;
    padding: 0 0.1875rem;
    margin: 0;
    margin-left: -0.1875rem;
}
.htmlText{
    line-height: 1.7;
    font-size: 14px;
    letter-spacing: 1px;
    color: #3d3d3d;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    

}
.fieldsetSection {
    border-left: none;
    border-right: none;
    border-top: none;
    margin: 0 0 30px;
    padding: 0 0 20px;    

}


