.getStarted {

  a {
    font-size: 14px;
  }
  
}

.getStarted_subContent{
  padding-left: 10px;
}

.getStarted h1 {
  margin: unset !important;
  font-size: 35px;
  color: #007e8d;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.getStarted h1::after {
  content: "";
  display: block;
  margin: 15px 0 40px;
  height: 1px;
  width: 150px;
  background: #007e8d;
}

.item:not(:last-child) {
  margin-bottom: 31px;
}