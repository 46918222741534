.contentContainer {
  display: flex;
  flex-direction: row;
}

.nav {
  padding-top: 15px;
  min-width: 220px;
}

.placementsPage {
  display: flex;
  flex-direction: column;
}

a {
  font-size: 14px;
  color: #007e8d;
  font-weight: 300;
  margin: 0;
  padding-bottom: 0;
  line-height: 1.4;
}

a:hover {
  color: #007e8d;
  cursor: pointer;
}

.nav > div > div {
  margin: 0;
  padding: 0;
}

.nav > div > div > h1 {
  margin: 0;
  padding: 0;
}

.mainContent {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.row {
  display: flex;
  flex-direction: row;
}

.maincontent > .row > div{
  flex: 1 1 0;
}

.gridTut {
  margin: 15px;
}

.gridTut >h2 {
  margin-bottom: 15px;
} 

.gridTut > p > a {
  font-size: 14px;
  color: #007e8d;
  font-weight: 300;
  padding-bottom: 0;
  line-height: 1.4;
}

@media (max-width: 800px) {
  .row {
    flex-direction: column;
  }
  .contentContainer {
  flex-direction: column;
}
}