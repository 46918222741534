.students {

}

.students h1 {
  margin: unset !important;
  font-size: 35px;
  color: #007e8d;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.students h1::after {
  content: "";
  display: block;
  margin: 15px 0 40px;
  height: 1px;
  width: 150px;
  background: #007e8d;
}

.item{
  margin-bottom: 31px;
}